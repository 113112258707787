import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  Alert,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  SelectChangeEvent,
} from "@mui/material";
import {
  IDiseaseItem,
  DataType,
  handleUpdateDiseaseBurden,
  IHealthAreaItem,
  IFilters,
} from "../../api/health_area"; 

interface EditDiseaseModalProps {
  healthAreas: IHealthAreaItem[];
  diseaseToEdit?: IDiseaseItem;
  isOpen: boolean;
  handleClose: () => void;
  onUpdate: () => void;
}

const EditDiseaseModal: React.FC<EditDiseaseModalProps> = ({
  healthAreas,
  diseaseToEdit,
  isOpen,
  handleClose,
  onUpdate,
}) => {
  const [formData, setFormData] = useState<{
    name: string;
    icode: string;
    source: string;
    sourceYear: number;
    disclaimer: string;
    dataType: DataType;
    healthAreaCategoryId: number;
    filters: IFilters;
  }>({
    name: "",
    icode: "",
    source: "",
    sourceYear: new Date().getFullYear(),
    disclaimer: "",
    dataType: DataType.Prevalence,
    healthAreaCategoryId: healthAreas.length > 0 ? healthAreas[0].id : -1,
    filters: {
      measure_type: false,
      race: true,
      sex: true,
      age: true,
    },
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState<string | null>(null);

  useEffect(() => {
    if (diseaseToEdit) {
      setFormData({
        name: diseaseToEdit.name || "",
        icode: diseaseToEdit.icode || "",
        source: diseaseToEdit.source || "",
        sourceYear: diseaseToEdit.sourceYear || new Date().getFullYear(),
        disclaimer: diseaseToEdit.disclaimer || "",
        dataType: diseaseToEdit.dataType || DataType.Prevalence,
        healthAreaCategoryId: diseaseToEdit.healthAreaCategoryId || (healthAreas.length > 0 ? healthAreas[0].id : -1),
        filters: diseaseToEdit.filters || {
          measure_type: false,
          race: true,
          sex: true,
          age: true,
        },
      });
      setErrors({});
      setSubmissionError(null);
    }
  }, [diseaseToEdit, healthAreas]);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (!formData.icode.trim()) {
      newErrors.icode = "ICode is required.";
    }
    if (!formData.source.trim()) {
      newErrors.source = "Source is required.";
    }
    if (!/^\d{4}$/.test(formData.sourceYear.toString())) {
      newErrors.sourceYear = "Valid 4-digit Source Year is required.";
    }
    if (!formData.disclaimer.trim()) {
      newErrors.disclaimer = "Disclaimer is required.";
    }
    if (!formData.dataType) {
      newErrors.dataType = "Data Type is required.";
    }
    if (!formData.healthAreaCategoryId || formData.healthAreaCategoryId === -1) {
      newErrors.healthAreaCategoryId = "Health Area is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name as string]: name === "sourceYear" ? Number(value) : value,
    }));
    setErrors((prev) => ({ ...prev, [name as string]: "" }));
  };

  const handleDataTypeChange = (
    event: SelectChangeEvent<DataType>
  ) => {
    const { value } = event.target;
    setFormData((prev) => ({
      ...prev,
      dataType: value as DataType,
      filters: value === DataType.Prevalence ?{...prev.filters, measure_type: false} : prev.filters
    }));
    setErrors((prev) => ({ ...prev, dataType: "" }));
  };

  const handleHealthAreaChange = (event: SelectChangeEvent<string>) => {
    const value = Number(event.target.value);
    setFormData((prev) => ({
      ...prev,
      healthAreaCategoryId: Number(value),
    }));
    setErrors((prev) => ({ ...prev, healthAreaCategoryId: "" }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        [name]: checked,
      },
    }));
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setSubmissionError(null);

    try {
      await handleUpdateDiseaseBurden(
        diseaseToEdit!.id,
        formData.name,
        formData.icode,
        formData.source,
        formData.sourceYear,
        formData.disclaimer,
        formData.dataType,
        formData.healthAreaCategoryId,
        formData.filters,
      );
      setIsSubmitting(false);
      onUpdate();
      handleClose();
    } catch (error: any) {
      console.error("Error updating disease:", error);
      setSubmissionError(
        error?.response?.data?.message || "Failed to update disease."
      );
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit Disease</DialogTitle>
      <DialogContent>
        {submissionError && (
          <Box mb={2}>
            <Alert severity="error">{submissionError}</Alert>
          </Box>
        )}

        {/* Editable Fields */}
        <FormControl fullWidth margin="normal" required error={Boolean(errors.healthAreaCategoryId)}>
          <InputLabel id="health-area-label">Health Area</InputLabel>
          <Select
            labelId="health-area-label"
            label="Health Area"
            name="healthAreaCategoryId"
            value={formData?.healthAreaCategoryId?.toString()}
            onChange={handleHealthAreaChange}
          >
            {healthAreas.map((ha) => (
              <MenuItem key={ha.id} value={ha.id}>
                {ha.name}
              </MenuItem>
            ))}
          </Select>
          {errors.healthAreaCategoryId && (
            <Typography variant="caption" color="error">
              {errors.healthAreaCategoryId}
            </Typography>
          )}
        </FormControl>

        <FormControl fullWidth margin="normal" required error={Boolean(errors.dataType)}>
          <InputLabel id="data-type-label">Data Type</InputLabel>
          <Select
            labelId="data-type-label"
            label="Data Type"
            name="dataType"
            value={formData.dataType}
            onChange={handleDataTypeChange}
          >
            <MenuItem value={DataType.Incidence}>Incidence</MenuItem>
            <MenuItem value={DataType.Prevalence}>Prevalence</MenuItem>
          </Select>
          {errors.dataType && (
            <Typography variant="caption" color="error">
              {errors.dataType}
            </Typography>
          )}
        </FormControl>

        <TextField
          fullWidth
          margin="normal"
          label="Disease Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          fullWidth
          margin="normal"
          label="ICode"
          name="icode"
          value={formData.icode}
          onChange={handleInputChange}
          required
          error={!!errors.icode}
          helperText={errors.icode}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Source"
          name="source"
          value={formData.source}
          onChange={handleInputChange}
          required
          error={!!errors.source}
          helperText={errors.source}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Source Year"
          name="sourceYear"
          type="number"
          value={formData.sourceYear}
          onChange={handleInputChange}
          required
          error={!!errors.sourceYear}
          helperText={errors.sourceYear}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Disclaimer"
          name="disclaimer"
          value={formData.disclaimer}
          onChange={handleInputChange}
          required
          multiline
          rows={3}
          error={!!errors.disclaimer}
          helperText={errors.disclaimer}
        />

        {/* Filters */}
        <Box mt={2}>
          <Typography variant="subtitle1">Filters</Typography>
          <FormControlLabel
            label="Measure Type"
            control={
              <Checkbox
                name="measure_type"
                checked={formData.filters.measure_type}
                onChange={handleCheckboxChange}
                disabled={formData.dataType !== DataType.Incidence}
              />
            }
          />
          <FormControlLabel
            label="Race/Ethnicity"
            control={
              <Checkbox
                name="race"
                checked={formData.filters.race}
                onChange={handleCheckboxChange}
              />
            }
          />
          <FormControlLabel
            label="Age"
            control={
              <Checkbox
                name="age"
                checked={formData.filters.age}
                onChange={handleCheckboxChange}
              />
            }
          />
          <FormControlLabel
            label="Sex"
            control={
              <Checkbox
                name="sex"
                checked={formData.filters.sex}
                onChange={handleCheckboxChange}
              />
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
        >
          {isSubmitting ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDiseaseModal;
