import React, { useState } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  CircularProgress,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Collapse,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Edit, Add } from "@mui/icons-material";
import {
  IDiseaseItem,
  IDiseaseFormData,
  IHealthAreaItem,
  DataType,
  IDiseaseFilters,
} from "../../api/health_area";

interface ManageDiseasesStepProps {
  healthArea?: IHealthAreaItem;
  formData: React.SetStateAction<any>;
  setFormData: React.SetStateAction<any>;
}

const ManageDiseasesStep: React.FC<ManageDiseasesStepProps> = ({
  healthArea = { id: -1 },
  formData,
  setFormData,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [formDataDisease, setFormDataDisease] = useState<IDiseaseFormData>({
    name: "",
    icode: "",
    source: "",
    sourceYear: new Date().getFullYear(),
    disclaimer: "",
    dataType: DataType.Prevalence,
    healthAreaCategoryId: healthArea.id,
    filters: {
      measure_type: false,
      race: true,
      sex: true,
      age: true,
    }
  });
  const [editingId, setEditingId] = useState<number | null>(null);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    if (!formDataDisease.name.trim()) {
      newErrors.name = "Disease Name is required";
    }
    if (!formDataDisease.icode.trim()) {
      newErrors.icode = "ICode is required";
    }
    if (!formDataDisease.source.trim()) {
      newErrors.source = "Source is required";
    }
    if (!formDataDisease.sourceYear || formDataDisease.sourceYear <= 0) {
      newErrors.sourceYear = "Valid Source Year is required";
    }
    if (!formDataDisease.disclaimer.trim()) {
      newErrors.disclaimer = "Disclaimer is required";
    }
    if (!formDataDisease.dataType) {
      newErrors.dataType = "Data Type is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormDataDisease((prev) => ({
      ...prev,
      [name]: name === "sourceYear" ? Number(value) : value,
    }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleDataTypeChange = (
    event: SelectChangeEvent<DataType>
  ) => {
    const { value } = event.target;
    setFormDataDisease((prev) => ({
      ...prev,
      dataType: value as DataType,
    }));
    setErrors((prev) => ({ ...prev, dataType: "" }));
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    setFormDataDisease((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        [name]: checked,
      }
    }));
  }

  const handleSave = async () => {
    if (!validateForm()) {
      return;
    }

    setIsSaving(true);

    try {
      setFormData({
        ...formData,
        diseases: [
          ...formData.diseases,
          formDataDisease,
        ]
      });

      setIsFormOpen(false);
    } catch (error) {
      console.error("Error saving disease:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = (disease: IDiseaseItem) => {
    setEditingId(disease.id);
    setFormDataDisease({
      name: disease.name,
      icode: disease.icode,
      source: disease.source,
      sourceYear: disease.sourceYear,
      disclaimer: disease.disclaimer,
      dataType: disease.dataType,
      healthAreaCategoryId: disease.healthAreaCategoryId,
      filters: {
        ...disease.filters,
      }
    });
    setIsFormOpen(true);
    setErrors({});
  };

  const handleOpenForm = () => {
    setIsFormOpen(true);
    setEditingId(null);
    setFormDataDisease({
      name: "",
      icode: "",
      source: "",
      sourceYear: new Date().getFullYear(),
      disclaimer: "",
      dataType: DataType.Prevalence,
      healthAreaCategoryId: healthArea.id,
      filters: {
        measure_type: false,
        race: true,
        age: true,
        sex: true,
      }
    });
    setErrors({});
  };

  const handleCloseForm = () => {
    setIsFormOpen(false);
    setEditingId(null);
    setFormDataDisease({
      name: "",
      icode: "",
      source: "",
      sourceYear: new Date().getFullYear(),
      disclaimer: "",
      dataType: DataType.Prevalence,
      healthAreaCategoryId: healthArea.id,
      filters: {
        measure_type: false,
        race: true,
        age: true,
        sex: true,
      }
    });
    setErrors({});
  };

  return (
    <Box
      sx={{
        marginTop: 5,
      }}
    >
      {/* Add Disease Button */}
      <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={handleOpenForm}
        >
          Add Disease
        </Button>
      </Box>

      {/* Disease Form */}
      <Collapse in={isFormOpen}>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mb: 4,
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Disease Name"
            name="name"
            value={formDataDisease.name}
            onChange={handleInputChange}
            required
            error={Boolean(errors.name)}
            helperText={errors.name}
          />
          <TextField
            label="ICode"
            name="icode"
            value={formDataDisease.icode}
            onChange={handleInputChange}
            required
            error={Boolean(errors.icode)}
            helperText={errors.icode}
          />
          <TextField
            label="Source"
            name="source"
            value={formDataDisease.source}
            onChange={handleInputChange}
            required
            error={Boolean(errors.source)}
            helperText={errors.source}
          />
          <TextField
            label="Source Year"
            name="sourceYear"
            type="number"
            value={formDataDisease.sourceYear}
            onChange={handleInputChange}
            required
            error={Boolean(errors.sourceYear)}
            helperText={errors.sourceYear}
          />
          <TextField
            label="Disclaimer"
            name="disclaimer"
            value={formDataDisease.disclaimer}
            onChange={handleInputChange}
            required
            error={Boolean(errors.disclaimer)}
            helperText={errors.disclaimer}
            multiline
            rows={3}
          />
          <FormControl fullWidth required error={Boolean(errors.dataType)}>
            <InputLabel id="data-type-label">Data Type</InputLabel>
            <Select
              labelId="data-type-label"
              label="Data Type"
              name="dataType"
              value={formDataDisease.dataType}
              onChange={handleDataTypeChange}
            >
              <MenuItem value={DataType.Incidence}>Incidence</MenuItem>
              <MenuItem value={DataType.Prevalence}>Prevalence</MenuItem>
            </Select>
            {errors.dataType && (
              <Typography variant="caption" color="error">
                {errors.dataType}
              </Typography>
            )}
          </FormControl>
          
          <FormControl fullWidth>
            Filters
            {formDataDisease.dataType == DataType.Incidence && (
              <FormControlLabel 
                label="Measure Type" 
                control={
                  <Checkbox
                    name="measure_type"
                    defaultChecked={formDataDisease.dataType == DataType.Incidence}
                    checked={formDataDisease.filters.measure_type}
                    onChange={handleCheckboxChange}
                  />
                } 
              />
            )}
            
            <FormControlLabel
              label="Race/Ethnicity" 
              control={
                <Checkbox
                  defaultChecked
                  name="race"
                  checked={formDataDisease.filters.race}
                  onChange={handleCheckboxChange}
                />} 
            />
            <FormControlLabel 
              label="Age" 
              control={
                <Checkbox
                  defaultChecked
                  name="age"
                  checked={formDataDisease.filters.age}
                  onChange={handleCheckboxChange}
                />} 
            />
            <FormControlLabel 
              label="Sex" 
              control={
                <Checkbox 
                  defaultChecked
                  name="sex"
                  checked={formDataDisease.filters.sex}
                  onChange={handleCheckboxChange}
                  />} 
            />
          </FormControl>

          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <Button onClick={handleCloseForm} disabled={isSaving}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isSaving}
            >
              {editingId ? "Update Disease" : "Add Disease"}
            </Button>
          </Box>
        </Box>
      </Collapse>

      {/* Disease Table */}
      {isLoading ? (
        <Box sx={{ textAlign: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : formData.diseases.length === 0 ? (
        <Typography sx={{ textAlign: 'center' }}>No diseases found for this health area.</Typography>
      ) : (
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>ICode</TableCell>
                <TableCell>Source</TableCell>
                <TableCell>Source Year</TableCell>
                <TableCell>Disclaimer</TableCell>
                <TableCell>Data Type</TableCell>
                <TableCell>Filters</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formData.diseases.filter(Boolean).map((disease: IDiseaseItem) => (
                disease && (
                  <TableRow key={disease.id}>
                    <TableCell>{disease.name}</TableCell>
                    <TableCell>{disease.icode}</TableCell>
                    <TableCell>{disease.source}</TableCell>
                    <TableCell>{disease.sourceYear}</TableCell>
                    <TableCell>{disease.disclaimer}</TableCell>
                    <TableCell>{disease.dataType}</TableCell>
                    <TableCell>
                      {disease.filters.measure_type && 'Measure Type'}
                      {disease.filters.race && ' Race'}
                      {disease.filters.sex && ' Sex'}
                      {disease.filters.age && ' Age'}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        color="primary"
                        onClick={() => handleEdit(disease)}
                      >
                        <Edit />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default ManageDiseasesStep;