import { axiosInstanceAuth } from "./setup";

export interface IHealthAreaItem {
  id: number;
  name: string;
  icode: string;
  isPublished: boolean;
}

interface IHealthAreaApiResponseData {
  status: string;
  data: IHealthAreaItem[];
}

interface IHealthAreaApiResponse {
  status: number;
  data: IHealthAreaApiResponseData;
}

export interface IFilters {
  measure_type: boolean,
  race: boolean,
  sex: boolean,
  age: boolean,
}

export const handleGetHealthAreaCategories = (): Promise<IHealthAreaApiResponse> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get(`/admin/fetch_health_categories`)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleCreateHealthAreaCategory = (formData: any): Promise<IHealthAreaApiResponse> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/create_health_category`, formData)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleUpdateHealthAreaCategory = (
  id: number,
  name: string,
  icode: string,
): Promise<IHealthAreaApiResponse> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .put(`/admin/update_health_category`, {
        id,
        name,
        icode,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handlePublishHealthAreaCategory = (
  id: number,
): Promise<IHealthAreaApiResponse> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .patch(`/admin/publish_health_category`, {
        id,
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteHealthAreaCategory = (id: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    axiosInstanceAuth()
      .delete("/admin/delete_health_category", {
        data: { id: parseInt(id) },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch(reject);
  });
};

export enum DataType {
  Incidence = "incidence",
  Prevalence = "prevalence",
}

export interface IDiseaseFormData {
  id?: number; // Optional for creation
  name: string;
  icode: string;
  source: string;
  sourceYear: number;
  disclaimer: string;
  dataType: DataType;
  healthAreaCategoryId: number;
  filters: IDiseaseFilters;
}

export interface IDiseaseItem {
  id: number;
  name: string;
  icode: string;
  source: string;
  sourceYear: number;
  disclaimer: string;
  dataType: DataType;
  healthAreaCategoryId: number;
  filters: IDiseaseFilters;
  HealthAreaCategory: IHealthAreaItem;
}

export interface IDiseaseFilters {
  measure_type: boolean;
  race: boolean;
  age: boolean;
  sex: boolean;
}

export interface IDiseaseApiResponseData {
  status: string;
  data: IDiseaseItem[];
}

export interface IDiseaseApiResponse {
  status: number;
  data: IDiseaseApiResponseData;
}

export const handleGetDiseaseBurdens = (): Promise<IDiseaseApiResponse> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .get(`/admin/fetch_disease_burdens`)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface IHandleCreateDiseaseBurdenFromData {
  id?: number;
  name: string;
  icode: string;
  diseases: IDiseaseItem[],
}

export const handleCreateDiseaseBurden = (formData: IHandleCreateDiseaseBurdenFromData): Promise<IDiseaseApiResponse> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .post(`/admin/create_disease_burden`, formData)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleUpdateDiseaseBurden = (
  id: number,
  name: string,
  icode: string,
  source: string,
  sourceYear: number,
  disclaimer: string,
  dataType: DataType,
  healthAreaCategoryId: number,
  filters: IFilters,

): Promise<IDiseaseApiResponse> => {
  return new Promise(async (resolve, reject) => {
    axiosInstanceAuth()
      .put(`/admin/update_disease_burden`, {
        id,
        name,
        icode,
        source,
        sourceYear,
        disclaimer,
        dataType,
        healthAreaCategoryId,
        filters
      })
      .then((result) => {
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteDiseaseBurden = (id: number): Promise<IDiseaseApiResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstanceAuth()
      .delete("/admin/delete_disease_burden", {
        data: { id },
      })
      .then((result) => {
        resolve(result.data)
      })
      .catch(reject);
  });
};